<template>
  <div>
    <v-dialog
        @keydown.esc="$store.commit('closeDialog', result.dialogRef)"
        v-model="dialog"
        :width="width"
        :fullscreen="fullscreen"
        no-click-animation
        persistent
        scrollable>
      <dynamicComponent
          v-for="type in content"
          :key="type"
          :type="type"
          :result="result"
          :dialog="dialog"
          :additional="{ extraAttr: dialogAttributes }"
          v-on="$listeners"></dynamicComponent>
    </v-dialog>
  </div>
</template>

<script>
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';

export default {
  components: {
    dynamicComponent,
  },
  created: function () {
    this.$store.state.closeDialog = true;

    /*  this.unsubscribe = this.$store.subscribe((mutation, state) => {
        console.log("subscribe called");
        if (mutation.type === "closeDialog") {

          this.dialog = state.dialogClose;
          // }
        }
      });*/
  },
  /*  mounted() {
    document.addEventListener("keydown", (e) => {
      console.log(e.keyCode,this.result.dialogRef);
      if (e.keyCode == 27) {
        this.$store.commit("closeDialog", this.result.dialogRef);
      }
    });
  },*/
  data() {
    return {
      dialog: true,
      // content: [],
      // result: null
    };
  },
  computed: {
    width() {
      let width = 600;
      //   console.log(this.result);
      /*   if (
           typeof this.result.json != "undefined" &&
           typeof this.result.json.dialogFormat != "undefined" &&
           typeof this.result.json.dialogFormat.width != "undefined"
         ) {
           width = this.result.json.dialogFormat.width;
         } */
      //minWidth is coming from php!
      if (
          typeof this.result.json != 'undefined' &&
          typeof this.result.json.dialogFormat != 'undefined' &&
          typeof this.result.json.dialogFormat.minWidth != 'undefined'
      ) {
        width = this.result.json.dialogFormat.minWidth;
      } else if (typeof this.result.formWidth != 'undefined') {
        width = this.result.formWidth;
      }
      return width;
    },

    /* dialog: function() {
        console.log("dailog container", this.$store.state.closeDialog);
        return this.$store.state.closeDialog;
      },*/
    // result: function() {
    //   let result = this.response.data.result;

    //   return result;
    // },
    content: function () {
      let content = this.result.content;
      return content;
    },
    fullscreen: function () {
      let fullscreen = false;
      if (
          typeof this.result.json != 'undefined' &&
          typeof this.result.json.dialogFormat != 'undefined' &&
          this.result.json.dialogFormat.maximized == true
      ) {
        fullscreen = true;
      }
      return fullscreen;
    },
  },
  watch: {
    response: function () {
      console.log('changed');
    },
  },
  props: ['result', 'dialogAttributes'],
  methods: {},
};
</script>
