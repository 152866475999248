import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_DICTIONARY_SERVICE}/dictionary-service`);

const dictionaryService = {
    getRadiusAttributes(then, error) {
        service.getRequest(1, 'radius', null, then,
            error,
        );
    }
};

export default dictionaryService;